<template>
  <div class="relative overflow-x-auto bg-white">
    <transition appear mode="in-out" v-if="props.isLoading">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              v-for="(key, id) in 40"
              :key="id"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider animate-pulse"
            >
              <div class="h-4 bg-gray-300 rounded w-1/2"></div>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr>
            <td
              v-for="(key, id) in 40"
              :key="id"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider animate-pulse"
            >
              <div class="h-4 bg-gray-300 rounded w-1/2"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </transition>
    <table class="table-auto w-full text-sm text-left overflow-hidden" v-else>
      <thead class="text-sm text-teal border">
        <tr>
          <th
            class="px-6 py-4 text-white bg-teal"
            :class="{ 'w-36 text-center': index === 1 }"
            v-for="(header, index) in props?.tableHeadings"
            :key="index"
          >
            {{ header }}
          </th>
        </tr>
      </thead>

      <transition appear mode="in-out">
        <tbody>
          <tr class="border-b" v-for="(data, index) in practices" :key="index">
            <td class="sticky left-0 px-8 py-4 border-r bg-white text-black">
              <span class="py-2">{{ data?.practice_name }}</span>
            </td>
            <td class="px-8 py-4 border-r text-center w-1/6">
              <div v-if="data?.instance_requests">
                <template
                  v-for="(requests, key) in data?.instance_requests"
                  :key="key"
                >
                  <div v-if="isClicked[index]">
                    <button
                      class="text-red-600 border border-red-600 px-2 py-1 rounded-md m-2 hover:bg-red-600 hover:text-white cursor-pointer"
                      @click="
                        handleStatusChange(
                          false,
                          index,
                          requests?.id,
                          data?.instance_requests.length
                        )
                      "
                    >
                      Decline
                    </button>
                    <button
                      class="text-teal border border-teal px-2 py-1 rounded-md hover:bg-teal hover:text-white cursor-pointer"
                      @click="
                        handleStatusChange(
                          true,
                          index,
                          requests?.id,
                          data?.instance_requests.length
                        )
                      "
                    >
                      Approve
                    </button>
                  </div>
                  <div
                    v-else-if="isRequests[index]"
                    :class="isRequestsStyle[index]"
                  >
                    {{
                      isRequests[index] === 1
                        ? requests?.type.charAt(0).toUpperCase() +
                          requests?.type.slice(1) +
                          " Approved!"
                        : isRequests[index] === -1
                        ? requests?.type.charAt(0).toUpperCase() +
                          requests?.type.slice(1) +
                          " Declined!"
                        : ""
                    }}
                  </div>
                  <span
                    v-else
                    class="p-1 rounded-md"
                    :class="requests?.status === 0 ? getStatusClass : ''"
                  >
                    <div
                      class="text-teal font-medium"
                      v-if="requests?.status === 1"
                    >
                      {{ requests?.type === "redo" ? "Redo" : "Extension" }}
                      Approved!
                    </div>
                    <div
                      class="text-red-500 font-medium"
                      v-else-if="requests?.status === 2"
                    >
                      {{ requests?.type === "redo" ? "Redo" : "Extension" }}
                      Declined!
                    </div>
                    <span
                      @click="
                        handleRedoClick(index, data?.instance_requests.length)
                      "
                      class="cursor-pointer"
                      v-else
                    >
                      {{ requests?.type === "redo" ? "Redo" : "Extension" }}
                    </span>
                  </span>
                </template>
              </div>
              <span v-else class="p-1 rounded-md" :class="getStatusClass">
                <span
                  v-if="loading"
                  class="inline-block bg-gray-200 h-4 w-20 rounded animate-pulse"
                  >&nbsp;</span
                >
                <span v-else>
                  <span v-if="practices">
                    {{ statusText }}
                  </span>
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </transition>
    </table>
    <div v-if="!props.isLoading">
      <div
        v-if="practices?.length === 0"
        class="flex flex-col items-center justify-center text-center w-full py-10"
      >
        <div class="rounded-full p-12 icon-bg">
          <img src="../../../assets/icons/noData.svg" class="w-32" />
        </div>
        <div class="mt-4 text-teal font-medium flex align-middle">
          <i class="material-icons text-teal w-8">info_outlined</i>
          No Data Found Against This Shift
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  toRefs,
  computed,
  ref,
  defineEmits,
  onMounted,
  watch,
} from "vue";

const emit = defineEmits(["redo-click"]);
const props = defineProps({
  tableData: {
    type: Array,
    required: true,
  },
  tableHeadings: {
    type: Array,
    required: true,
  },
  statusvalue: {
    type: Number,
  },
  requests: {
    type: Object,
  },
  isLoading: {
    type: Boolean,
  },
});

const { tableData, requests } = toRefs(props);
const isRequests = ref([]);
const loading = ref(true);
const isRequestsStyle = ref([]);

const isClicked = ref([]);
const handleRedoClick = (id, size) => {
  isClicked.value = Array(size).fill(false);
  isClicked.value = isClicked.value.map(() => false);
  isClicked.value[id] = true;
};

const handleStatusChange = (isApproved, id, requestId, size) => {
  if (isApproved) {
    isRequestsStyle.value[id] = "text-teal font-medium cursor-pointer";
    isRequests.value = Array(size).fill(false);
    isRequests.value = isRequests.value.map(() => false);
    isRequests.value[id] = 1;
  } else {
    isRequestsStyle.value[id] = "text-red font-medium cursor-pointer";
    isRequests.value = Array(size).fill(false);
    isRequests.value = isRequests.value.map(() => false);
    isRequests.value[id] = -1;
  }
  isClicked.value[id] = false;
  emit("redo-click", isApproved, requestId);
};

const statusText = computed(() => {
  switch (props.statusvalue) {
    case 1:
      return "Pending";
    case 2:
      return "Overdue";
    case 3:
      return "Expired";
    case 4:
      return "Redo";
    default:
      return "Unknown";
  }
});

const getStatusClass = computed(() => {
  switch (props.statusvalue) {
    case 1:
      return "text-blue-500 bg-blue-100 bg-opacity-50";
    case 2:
      return "text-red-500 bg-red-100 bg-opacity-50";
    case 3:
      return "text-black-500 bg-gray-100 bg-opacity-50";
    case 4:
      return "text-teal border border-teal px-2 py-1 hover:bg-teal hover:text-white";
    default:
      return "bg-opacity-50";
  }
});

const practices = computed(() => {
  if (props.statusvalue === 4) {
    return requests?.value.data;
  } else {
    return tableData?.value.data;
  }
});

watch(
  () => props.statusvalue,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      loading.value = true;
    }
  }
);

watch(
  () => tableData.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      loading.value = false;
    }
  }
);

onMounted(() => {
  setTimeout(() => {
    loading.value = false;
  }, 1000);
});
</script>
<style scoped>
.icon-bg {
  background: rgba(235, 248, 247, 1);
}
</style>
