<!-- @format -->

<template>
  <div class="relative overflow-x-auto rounded-xl bg-white shadow-md">
    <div class="px-6 py-3 flex justify-between">
      <div>
        <span class="text-xl font-medium">{{ activeShiftName }}</span>
        <h4 class="text-gray-600">Tasks</h4>
      </div>
    </div>
    <div
      class="drop-shadow-2xl w-full px-6 py-3 flex justify-between botder-t-2 border-l-0 border-r-0 border-b-0 border border-gray-200"
    >
      <Tabs
        :bottomBorder="false"
        :key="tabsData"
        :tabsData="tabsData"
        :activeTabIndex="tabIndex"
        @index="switchTab"
      />
      <div
        class="border-2 border-teal text-teal rounded px-2 flex justify-between items-center gap-2 my-1 cursor-pointer"
        v-if="activeTabValue !== 4"
      >
        {{
          moment(currentDate).format("MMMM DD, YYYY") === "Invalid date"
            ? "Enter new Date"
            : moment(currentDate).format("MMMM DD, YYYY")
        }}
        <input
          type="date"
          class="focus:outline-none cursor-pointer"
          v-model="currentDate"
          :max="formatDate(date)"
          @change="defaultDate"
        />
      </div>
    </div>

    <TableSkeletonLoader
      v-if="loadingHeader && loadingTable"
      :loader="loadingHeader && loadingTable"
      :cols="6"
      :rows="6"
    />
    <div v-else>
      <SubmittedActivitiesTable
        v-if="activeTabValue === 0"
        :isLoading="loading"
        :tableData="shiftSubmissions?.data"
        :tableHeadings="shiftActivities"
        :key="shiftSubmissions?.data"
        @practice-clicked="setRouteParams"
      />
    </div>

    <ModelDayActivitiesStatusTable
      v-if="activeTabValue != 0"
      :key="shiftSubmissions?.data"
      :isLoading="loading"
      :statusvalue="activeTabValue"
      :requests="instanceRequests"
      :tableData="shiftSubmissions"
      :tableHeadings="modelDayActivitiesStatusTableHeadings"
      @redo-click="handleRedoClick"
    />
    <Pagination
      v-if="paginationActivity?.last_page !== 1"
      :currentPage="paginationActivity?.current_page"
      :totalPages="paginationActivity?.last_page"
      @page-changed="fetchPage"
    />
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  computed,
  watchEffect,
  defineEmits,
  watch,
  toRefs,
  onMounted,
} from "vue";

import TableSkeletonLoader from "@/components/newUi/atoms/TableSkeletonLoader.vue";
import Pagination from "@/components/newUi/atoms/Pagination.vue";
import Tabs from "@/components/newUi/baseComponents/Tabs.vue";
import SubmittedActivitiesTable from "@/components/newUi/tables/SubmittedActivitiesTable.vue";
import ModelDayActivitiesStatusTable from "@/components/newUi/tables/ModelDayActivitiesStatusTable.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from "moment";

/**------------------------------------------------ */

const store = useStore();
const loading = ref(false);
const date = new Date();
const router = useRouter();
const emit = defineEmits(["refresh-template", "date-changed"]);
const pageNumber = ref(1);
const modelDayActivitiesStatusTableHeadings = ref(["Practices List", "Status"]);
const statusId = ref(1);
const flag = ref(false);
const loadingTable = ref(true);
const loadingHeader = ref(true);
const tabIndex = ref();
const props = defineProps({
  activeShift: {
    type: Number,
    required: true,
  },
  activeRegion: {
    type: Number,
    required: true,
  },
});

const { activeRegion } = toRefs(props);

/**---------------------------------------
 * to format date in the year Month and day
 * ----------------------------------------
 */

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const currentDate = ref(formatDate(date));
emit("date-changed", currentDate.value);

const defaultDate = (event) => {
  if (!event.target.value) {
    currentDate.value = formatDate(date);
  }
};

/**
 * -------------------------------------------------------------------
 * finding the active shift if the status is 1 or 2 else it is the eve
 * -------------------------------------------------------------------
 */

const activeShiftName = computed(() => {
  return props.activeShift === 1
    ? "Morning 8:00am"
    : props.activeShift === 2
    ? "Mid-Day 11:00am"
    : "Evening 11:00am";
});

/**
 * ---------------------------------------------------------
 * finding the pagination for the instance req or submission
 * ---------------------------------------------------------
 */

const paginationActivity = computed(() => {
  if (activeTabValue.value === 4) {
    return instanceRequests.value;
  } else {
    return shiftSubmissions.value;
  }
});

/**
 * -------------------------------------------------------
 * finding the active tab and mapping according to backend
 * -------------------------------------------------------
 */

const activeTabValue = ref(0);
const switchTab = (activeTab) => {
  activeTabValue.value = activeTab;
  switch (tabsData.value[activeTab]?.value + 1) {
    case 1:
      statusId.value = 1;
      return;
    case 2:
      statusId.value = 0;
      return;
    case 3:
      statusId.value = 2;
      return;
    case 4:
      statusId.value = 3;
      return;
    case 5:
      statusId.value = 4;
      return;
    default:
      break;
  }
};
const tabsData = computed(() => {
  const count = instanceCount.value;
  if (count) {
    return [
      { name: `Submitted (${count.submitted})`, value: 0 },
      { name: `Pending (${count.pending})`, value: 1 },
      { name: `Overdue (${count.overdue})`, value: 2 },
      { name: `Expired (${count.expired})`, value: 3 },
      { name: `Requests (${count.redo + count.extension})`, value: 4 },
    ];
  }
  return [];
});

/**
 * ----------------------------------------------------------------
 * Handling the redo click to approve or disaprove request for redo
 * ----------------------------------------------------------------
 */

const handleRedoClick = async (isApproved, instanceId) => {
  if (isApproved) {
    await store
      .dispatch("hqModelDay/updateInstanceRequestStatus", {
        instance_request: parseInt(instanceId),
        status: 1,
      })
      .then(() => {
        flag.value = true;
        emit("refresh-template", flag.value);
        tabIndex.value = 1;
        switchTab(1);
      });
  } else {
    await store
      .dispatch("hqModelDay/updateInstanceRequestStatus", {
        instance_request: parseInt(instanceId),
        status: 2,
      })
      .then(() => {
        flag.value = true;
        emit("refresh-template", flag.value);
        tabIndex.value = 0;
        switchTab(0);
      });
  }
};

/**
 * ---------------------------------------------------------------
 * setting the route params for the single submission details page
 * ---------------------------------------------------------------
 */

const setRouteParams = (id, practiceName, instanceId) => {
  localStorage.setItem("practiceName", practiceName);
  router.push({
    name: "hq-ModelDay-monitoringOfPracticeFunctionsModelDaySinglePractice",
    params: {
      practiceId: id,
      shiftId: props.activeShift,
      instanceId: instanceId,
    },
  });
};

const fetchPage = (page) => {
  if (page > shiftSubmissions.value?.last_page) {
    return;
  }
  pageNumber.value = page;
};

/**
 * -------
 * Getters
 * -------
 */

const instanceCount = computed(() => {
  return store.getters["hqModelDay/getInstanceCount"];
});

const shiftActivities = computed(() => {
  return store.getters["hqModelDay/getActivities"];
});

const shiftSubmissions = computed(() => {
  return store.getters["hqModelDay/getInstanceSubmissions"];
});

const instanceRequests = computed(() => {
  return store.getters["hqModelDay/getInstanceRequests"];
});

/**
 * ----------------------------------------------------------------------------
 * Watching the active shift state if the state changes the date value resetted
 * ----------------------------------------------------------------------------
 */

watch(
  () => props.activeShift,
  (newActiveShift, oldActiveShift) => {
    if (newActiveShift !== oldActiveShift) {
      tabIndex.value = 0;
      switchTab(0);
      currentDate.value = formatDate(date);
    }
  }
);

watch(currentDate, (newValue) => {
  tabIndex.value = activeTabValue.value;
  switchTab(activeTabValue.value);
  emit("date-changed", newValue);
});

/**
 * -------------------------------------------------------------
 * calls in watcheffect so that it rerender as the value changes
 * -------------------------------------------------------------
 */
onMounted(() => {
  watchEffect(async () => {
    loadingTable.value = true;
    if (props.activeShift) {
      await store
        .dispatch("hqModelDay/fetchModelDayActivities", {
          shift: props.activeShift,
          region: activeRegion.value == 1 ? ["Wales"] : ["England"],
          date: currentDate.value,
        })
        .then(() => {
          switchTab(0);
          loadingHeader.value = false;
        });
    }
  });

  watchEffect(async () => {
    if (activeTabValue.value === 4) {
      if (props.activeShift) {
        loading.value = true;
        await store
          .dispatch("hqModelDay/fetchInstanceRequests", {
            shift: props.activeShift,
            page: pageNumber.value,
            region: activeRegion.value == 1 ? ["Wales"] : ["England"],
          })
          .then(() => {
            loading.value = false;
          });
      }
    } else {
      loadingTable.value = true;
      if (props.activeShift && currentDate.value) {
        loading.value = true;
        await store
          .dispatch("hqModelDay/fetchInstanceSubmissions", {
            shift: props.activeShift,
            status: statusId.value,
            page: pageNumber.value,
            date: currentDate.value,
            region: activeRegion.value == 1 ? ["Wales"] : ["England"],
          })
          .then(() => {
            loading.value = false;
            loadingTable.value = false;
          });
      }
    }
  });
});
</script>

<style scoped>
::-webkit-calendar-picker-indicator {
  background-image: url("../../../../assets/icons/calendar_month.svg");
}

input[type="date"]::-webkit-datetime-edit {
  display: none;
}
</style>
