<!-- @format -->

<template>
  <div
    class="grid gap-y-16 gap-x-12 sm:grid-col-1 lg:grid-cols-2 xl:grid-cols-3"
  >
    <div
      v-for="(card, idx) in cards"
      :key="idx"
      class="bg-white border border-gray-200 shadow-md rounded-md cursor-pointer"
      @click="activeShift(idx)"
    >
      <div
        class="font-bold rouned-t-md p-8 text-xl flex justify-between"
        :class="
          idx === activeItem || shiftActive[idx] === idx
            ? `border-teal border-2 rounded-t-md ${shiftActiveStyle[idx]}`
            : 'border-b-2 rounded-t-md'
        "
      >
        <div class="flex items-center">
          {{ card.title }}
        </div>

        <div
          class="relative group px-2"
          @mouseenter="showTooltip[idx] = true"
          @mouseleave="showTooltip[idx] = false"
        >
          <i
            class="material-icons w-7 border border-teal rounded text-base pl-1.5 cursor-pointer"
            :class="
              idx === activeItem || shiftActive[idx] === idx
                ? 'bg-white text-teal'
                : ' text-teal'
            "
            :style="{
              background: idx === activeItem ? '#f0f0f0 !important' : 'white',
              color: idx === activeItem ? 'gray !important' : 'white',
              border: idx === activeItem ? '1px solid gray !important' : '',
              pointerEvents: idx === activeItem ? 'none' : 'auto',
            }"
            @click="editShift(idx)"
            >edit_outlined</i
          >
          <div v-if="showTooltip[idx]">
            <div
              v-if="idx === activeItem"
              class="absolute left-1/2 transform -translate-x-1/2 -top-10 mt-2 bg-gray-50 border border-gray-500 text-xs rounded py-1 px-2 shadow"
            >
              <div
                class="whitespace-nowrap capitalize text-gray-600 font-light"
              >
                Cannot Edit Active Shift
                <div
                  class="absolute left-1/2 transform -translate-x-1/2 w-2 h-2 bg-gray-50 border-b border-gray-500 border-r rotate-45"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModelDayDoughnutChart
        :index="idx"
        :enabled="idx === activeCard(idx)"
        :chartData="data"
        :key="data"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, toRefs, defineEmits } from "vue";
import ModelDayDoughnutChart from "../charts/ModelDayDoughnutChart.vue";
import { useRouter } from "vue-router";
const props = defineProps({
  cards: {
    type: Array,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
  activeRegion: {
    type: Number,
    required: true,
  },
});

const { cards, data, activeRegion } = toRefs(props);

const router = useRouter();
const emits = defineEmits(["active-shift"]);
const showTooltip = ref([false, false, false]);
const shiftActive = ref([]);
const shiftActiveStyle = ref([]);
const activeCard = (id) => {
  if (shiftActive.value?.length > 0) {
    return shiftActive.value[id];
  } else {
    return activeItem.value;
  }
};

const currentShift = () => {
  const time = new Date().toLocaleTimeString("en-GB", {
    timeZone: "Europe/London",
  });
  const currentHour = parseInt(time);
  shiftActive.value = [];
  shiftActiveStyle.value = [];
  if (currentHour >= 8 && currentHour < 11) {
    shiftActive.value[0] = 0;
    shiftActiveStyle.value[0] = "bg-teal text-white";
    return 0;
  } else if (currentHour >= 11 && currentHour < 16) {
    shiftActive.value[1] = 1;
    shiftActiveStyle.value[1] = "bg-teal text-white";
    return 1;
  } else if (currentHour >= 16 && currentHour < 20) {
    shiftActive.value[2] = 2;
    shiftActiveStyle.value[2] = "bg-teal text-white";
    return 2;
  } else {
    emits("active-shift", 2);
  }
};

const activeItem = ref(currentShift());

const editShift = (id) => {
  router.push({
    name: "hq-ModelDay-monitoringOfPracticeFunctionsModelDayAddNewQuestions",
    params: { isNew: 0, shiftId: id, regionId: activeRegion.value },
  });
};

const activeShift = (id) => {
  shiftActive.value = [];
  shiftActiveStyle.value = [];
  shiftActive.value[id] = id;
  shiftActiveStyle.value[id] = "bg-teal text-white";
  emits("active-shift", shiftActive.value[id]);
};

emits("active-shift", activeItem.value);

onMounted(() => {
  currentShift();
});
</script>
