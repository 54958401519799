<!-- @format -->

<template>
  <div class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-6">
      <MopfButtonsGroup :buttons="buttons" />
    </div>
    <Tabs
      :key="tabsData"
      :tabsData="tabsData"
      :activeTabIndex="tabIndex"
      @index="switchTab"
    />
    <div>
      <ModelDayChartCard
        :cards="cards"
        @active-Shift="setActiveShift"
        :data="totalStatusCount"
        :activeRegion="tabIndex"
      />
    </div>

    <div>
      <ModelDayShiftDetail
        :activeShift="activeShift"
        @refresh-template="setFlag"
        @date-changed="setDate"
        :activeRegion="tabIndex"
        :key="tabIndex"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import ModelDayChartCard from "@/components/newUi/baseComponents/ModelDayChartCard.vue";
import MopfButtonsGroup from "@/components/newUi/baseComponents/MopfButtonsGroup.vue";
import Tabs from "@/components/newUi/baseComponents/Tabs.vue";
import ModelDayShiftDetail from "./ModelDayShiftDetail.vue";

/**------------------------------------- */

const buttons = ref([
  {
    component:
      "hq-ModelDay-monitoringOfPracticeFunctionsModelDayAddNewQuestions",
    text: "Add Activities",
    icon: "backup_table",
    bgColor: "bg-aquamarine",
    textColor: "text-aquamarine",
    params: { isNew: 1 },
  },
  {
    component:
      "hq-ModelDay-monitoringOfPracticeFunctionsModelDayManagerThreshold",
    text: "   Manage Threshold",
    icon: "inbox_outlined",
    bgColor: "bg-teal",
    textColor: "text-teal",
  },
]);

const tabIndex = ref(0);

const tabsData = ref([
  {
    name: "England",
    value: 0,
  },
  { name: "Wales", value: 1 },
]);

const switchTab = (index) => {
  tabIndex.value = index;
};

const cards = ref([
  {
    title: "Morning 8:00 am",
  },
  {
    title: "Mid-Day 11:00 am",
  },
  {
    title: "Evening 4:00 pm",
  },
]);
const activeShift = ref(0);
const refreshFlag = ref(false);
const selectedDate = ref();
const store = useStore();
const totalStatusCount = computed(() => {
  const count = instanceCount?.value;
  return {
    Submitted: count?.submitted,
    Pending: count?.pending,
    Overdue: count?.overdue,
    Expired: count?.expired,
    Requests: count?.redo + count?.extension,
  };
});
const instanceCount = computed(() => {
  return store.getters["hqModelDay/getInstanceCount"];
});
const setFlag = (flag) => {
  refreshFlag.value = flag;
};
const setDate = (date) => {
  selectedDate.value = date;
};
/**
 * ------------------------------------------------------------------
 * setting the active shift according to the event emitted from child
 * ------------------------------------------------------------------
 */

const setActiveShift = (shift) => {
  activeShift.value = shift + 1;
};

watchEffect(async () => {
  if (activeShift.value && selectedDate.value) {
    const payload = {
      shift: activeShift.value,
      date: selectedDate.value,
      region: tabIndex.value ? ["Wales"] : ["England"],
      flag: refreshFlag.value,
    };
    await store.dispatch("hqModelDay/fetchInstanceCount", payload);
  }
});
</script>
