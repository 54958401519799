<template>
  <div>
    <div
      class="bg-teal text-xs rounded-md p-2 text-white absolute w-60 whitespace-normal truncate max-width z-30"
      :class="alignment(position)"
    >
      <div class="truncate max-width">
        {{ text }}
      </div>
    </div>
    <div
      class="absolute transform rotate-45 w-2 h-2 bg-teal"
      :class="alignTip(position)"
    ></div>
  </div>
</template>

<script setup>
import { defineProps, toRefs } from "vue";

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  position: {
    type: String,
    required: true,
  },
});

const { text, position } = toRefs(props);
const alignment = (align) => {
  if (align === "right" || align === "Right") {
    return "-top-2 left-full";
  } else if (align === "left" || align === "Left") {
    return "-top-2 right-full";
  } else if (align === "top" || align === "Top") {
    return "-top-14 -right-3";
  } else if (align === "bottom" || align === "Bottom") {
    return "top-4 -ml-12 left-6";
  }
};
const alignTip = (align) => {
  if (align.includes("top") || align.includes("Top")) {
    return "bottom-8 right-1 translate-y-1/2";
  } else {
    return "top-4 left-3 -translate-y-1/2";
  }
};
</script>

<style scoped>
.max-widht {
  max-width: 5px;
}
</style>
