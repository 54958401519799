<!-- @format -->

<template>
  <div class="relative overflow-x-auto bg-white">
    <div v-if="props.isLoading">
      <transition appear mode="in-out">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th
                v-for="id in 40"
                :key="id"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider animate-pulse"
              >
                <div class="h-4 bg-gray-300 rounded w-1/2"></div>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr>
              <td
                v-for="id in 40"
                :key="id"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider animate-pulse"
              >
                <div class="h-4 bg-gray-300 rounded w-1/2"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </transition>
    </div>
    <div v-else>
      <table class="table-auto text-sm text-left w-full">
        <thead class="text-sm text-teal border sticky top-0 z-30 bg-white">
          <tr>
            <th
              class="text-white w-56 bg-teal sticky left-0 z-50 px-6 py-4 border overflow-hidden whitespace-nowrap text-ellipsis"
            >
              Practice List
            </th>
            <th
              class="p-4 border"
              v-for="(header, index) in props?.tableHeadings"
              :key="index"
            >
              <div class="relative flex flex-col items-center">
                <div
                  class="truncate cursor-pointer text-center capitalize"
                  :class="tableHeadingLength > 13 ? 'w-16' : 'w-40'"
                  @mouseover="mouseOverHeader(index)"
                  @mouseleave="hoverHeader[index] = false"
                >
                  {{ header?.activity }}
                </div>
                <div class="absolute top-full z-50 w-10 capitalize">
                  <Tooltip
                    :text="header?.activity"
                    position="bottom"
                    v-show="hoverHeader[index]"
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody v-if="tableDataLength > 0">
          <tr
            class="border-b whitespace-nowrap"
            v-for="(data, id) in tableData"
            :key="id"
          >
            <td
              class="sticky left-0 px-8 py-4 border-r bg-white z-20"
              v-if="data?.submissions"
            >
              <span
                class="py-2 cursor-pointer"
                @click="
                  emitPracticeId(
                    data?.id,
                    data?.practice_name,
                    tableData[id]?.submissions[0]?.instance
                  )
                "
              >
                {{ data?.practice_name }}
              </span>
            </td>
            <template v-for="(value, index) of data?.submissions" :key="index">
              <td
                class="py-5 border-r text-center capitalize"
                v-if="value?.activity"
                :class="
                  value?.activity && id % 2 === 0
                    ? value?.answer?.includes('no') ||
                      value?.answer >
                        value?.activity?.thresholds?.[0]?.threshold
                      ? 'bg-red-50'
                      : 'bg-white'
                    : value?.answer?.includes('no') ||
                      value?.answer >
                        value?.activity?.thresholds?.[0]?.threshold
                    ? 'bg-red-50'
                    : 'bg-teal bg-opacity-10'
                "
              >
                <span v-if="value?.activity?.type?.includes('date')">
                  {{ moment(value?.answer).format("MMM DD, YYYY") }}
                </span>
                <span v-else>
                  {{ value?.answer ? value?.answer : "--" }}
                  <span
                    v-if="
                      value?.activity?.thresholds &&
                      value?.activity?.thresholds?.length > 0
                    "
                    class="text-teal"
                  >
                    ({{
                      value?.activity?.thresholds[0]?.threshold >= 0
                        ? value?.activity?.thresholds[0]?.threshold
                        : "--"
                    }})
                  </span>
                  <div class="flex justify-center">
                    <div
                      class="w-2 h-2 bg-teal rounded-full relative cursor-pointer"
                      @mouseover="hover[id][index] = true"
                      @mouseleave="hover[id][index] = false"
                      v-if="value?.answer === 'no'"
                    >
                      <Tooltip
                        :text="value?.reason"
                        position="top"
                        v-if="hover[id] && hover[id][index]"
                      />
                    </div>
                  </div>
                </span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!props.isLoading">
      <div
        v-if="tableData && tableData?.length === 0"
        class="flex flex-col items-center justify-center text-center w-full py-10"
      >
        <div class="rounded-full p-12 icon-bg">
          <img src="../../../assets/icons/noData.svg" class="w-32" />
        </div>
        <div class="mt-4 text-teal font-medium flex align-middle">
          <i class="material-icons text-teal w-8">info_outlined</i>
          No Submissions Found Against This Shift
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRefs, ref, defineEmits, watch, computed } from "vue";
import Tooltip from "../atoms/Tooltip.vue";
import moment from "moment";

const hover = ref([]);
const hoverHeader = ref([]);

const emit = defineEmits(["practice-clicked"]);

const tableHeadingLength = computed(() => {
  if (props.tableHeadings) return props.tableHeadings?.length;
  return 5;
});
const tableDataLength = computed(() => {
  if (tableData.value) return tableData.value?.length;
  return 5;
});

const emitPracticeId = (id, practiceName, instanceId) => {
  if (instanceId) emit("practice-clicked", id, practiceName, instanceId);
};

const mouseOverHeader = (id) => {
  hoverHeader.value[id] = true;
};

const props = defineProps({
  tableData: {
    type: Array,
    default: () => [],
  },
  tableHeadings: {
    type: Object,
  },
  isLoading: {
    type: Boolean,
  },
});

const { tableData } = toRefs(props);

const initializeHover = (data) => {
  hover.value = data.map((row) =>
    row.submissions ? Array(row.submissions.length).fill(false) : []
  );
};

watch(
  () => props.tableData,
  (newData) => {
    if (newData) {
      initializeHover(newData);
    }
  },
  { immediate: true, deep: true }
);
</script>

<style scoped>
.icon-bg {
  background: rgba(235, 248, 247, 1);
}
</style>
